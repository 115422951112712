<template>
  <div id="dialog-idea-plan-note-add-edit">
    <el-dialog
      :visible="dialogAddEditNoteVisible"
      :close-on-click-modal="true"
      top="10px"
      :class="noteColor"
      @close="outsideClick"
    >
      <div class="top-actions">
        <ib-tooltip
          placement="bottom"
          popper-class="plan-popper"
        >
          <div
            slot="content"
            class="tooltip-content"
          >
            <p
              class="tooltip-title"
              v-text="placeholderTitle"
            />
            <div v-html="placeholderText" />
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" v-bind:svg-inline="''" v-bind:width="'16'" v-bind:height="'16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M256 0C114.497 0 0 114.507 0 256c0 141.503 114.507 256 256 256 141.503 0 256-114.507 256-256C512 114.497 397.492 0 256 0zm0 472c-119.393 0-216-96.615-216-216 0-119.393 96.615-216 216-216 119.393 0 216 96.615 216 216 0 119.393-96.616 216-216 216z" fill="#76725e" data-original="#000000" xmlns="http://www.w3.org/2000/svg"/><path d="M256 214.33c-11.046 0-20 8.954-20 20v128.793c0 11.046 8.954 20 20 20s20-8.955 20-20.001V234.33c0-11.046-8.954-20-20-20z" fill="#76725e" data-original="#000000" xmlns="http://www.w3.org/2000/svg"/><circle cx="256" cy="162.84" r="27" fill="#76725e" data-original="#000000" xmlns="http://www.w3.org/2000/svg"/></svg>
        </ib-tooltip>

        <ib-tooltip popper-class="plan-popper">
          <template #content>
            <div class="tooltip-content">
              <p>{{ $t('changeColor') }}</p>
            </div>
          </template>

          <el-dropdown class="action-items" trigger="click" placement="bottom" @command="handleCommand">
            <span class="el-dropdown-link">
              <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 384 384" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M192 0C85.973 0 0 85.973 0 192s85.973 192 192 192c17.707 0 32-14.293 32-32 0-8.32-3.093-15.787-8.32-21.44-5.013-5.653-8-13.013-8-21.227 0-17.707 14.293-32 32-32h37.653c58.88 0 106.667-47.787 106.667-106.667C384 76.373 298.027 0 192 0zM74.667 192c-17.707 0-32-14.293-32-32s14.293-32 32-32 32 14.293 32 32-14.294 32-32 32zm64-85.333c-17.707 0-32-14.293-32-32s14.293-32 32-32 32 14.293 32 32-14.294 32-32 32zm106.666 0c-17.707 0-32-14.293-32-32s14.293-32 32-32 32 14.293 32 32-14.293 32-32 32zm64 85.333c-17.707 0-32-14.293-32-32s14.293-32 32-32 32 14.293 32 32-14.293 32-32 32z" fill="#76725e" data-original="#000000" xmlns="http://www.w3.org/2000/svg"/></svg>
            </span>

            <el-dropdown-menu slot="dropdown" class="action-dropdown colors">
              <el-dropdown-item command="default">
                <div class="color default" />
              </el-dropdown-item>
              <el-dropdown-item command="blue">
                <div class="color blue" />
              </el-dropdown-item>
              <el-dropdown-item command="red">
                <div class="color red" />
              </el-dropdown-item>
              <el-dropdown-item command="green">
                <div class="color green" />
              </el-dropdown-item>
              <el-dropdown-item command="yellow">
                <div class="color yellow" />
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </ib-tooltip>
      </div>
      <div class="form-wrapper">
        <el-form @keydown.enter.native="submitOnEnter">
          <el-form-item>
            <el-input
              v-model="form.title"
              :placeholder="$t('fields.noteTitle')"
              maxlength="100"
            />
          </el-form-item>

          <!-- Quill Wrapper -->
          <div class="quill-wrapper idea-plan-note">
            <quill-editor
              ref="quillio"
              v-model="form.text"
              :options="editorOptions"
              @change="inputTimeOut"
            />
          </div>
          <!-- /Quill Wrapper -->
        </el-form>
      </div>
      <el-button
        class="small"
        :disabled="isNoteEmpty || !isAddEnabled || isUnsupportedPluginOnIdeaPlan"
        :loading="loading"
        @click="saveNote"
      >
        {{ isCreateMode ? $t('add') : $t('save') }}
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import MixinUnsupportedPlugins from '@/mixins/unsupportedPlugins'
import { mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import quillPasteSmartOptions from '@/options/quillPasteSmartOptions'

export default {
  name: 'DialogIdeaPlanNoteAddEdit',

  components: {
    quillEditor
  },

  mixins: [MixinUnsupportedPlugins],

  props: {
    dialogAddEditNoteVisible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    placeholderTitle: {
      type: String,
      default: ''
    },
    placeholderText: {
      type: String,
      default: ''
    },
    sectionId: {
      type: Number,
      default: 0
    },
    id: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: ''
    },
    filledSections: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      clickedOnSaveButton: false,
      clickedOutside: false,
      ideaPlan: {},
      noteColor: '',
      totalSections: 14,
      form: {
        id: 0,
        title: this.title,
        text: this.text,
        color: '',
        ideaPlanId: 0,
        sectionId: 0
      },
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link'],
              ['clean']
            ]
          },
          ...quillPasteSmartOptions
        },
        placeholder: this.$t('fields.enterNoteContent')
      },
      loading: false
    }
  },

  computed: {
    isAddEnabled () {
      return this.form.title.length < 101
    },

    isCreateMode () {
      return this.form.id === 0
    },

    isNoteEmpty () {
      return !(this.form.title.length || this.form.text.length)
    }
  },

  mounted () {
    this.importNoteData()
  },

  methods: {
    ...mapActions('idea/ideaPlan', [
      'addNote',
      'updateNote'
    ]),

    handleCommand (command) {
      this.noteColor = command
      this.form.color = command

      this.$gtm.trackEvent({
        event: 'postColor'
      })
    },

    saveNote () {
      if (this.isUnsupportedPluginOnIdeaPlan) return
      this.clickedOnSaveButton = true
      if (this.isCreateMode) {
        this.onAddNote()
      } else {
        this.onUpdateNote()
      }
    },

    onAddNote () {
      this.loading = true
      this.form.ideaPlanId = this.$store.state.idea.ideaPlan.ideaPlans[0].id
      this.form.sectionId = this.sectionId
      this.addNote(this.form).then(_ => {
        if (this.filledSections === 0) {
          this.$gtm.trackEvent({
            event: 'postFirst'
          })
        }
        if (this.filledSections === this.totalSections - 1) {
          this.$gtm.trackEvent({
            event: 'postAll'
          })
        }

        this.closeDialog()
      }).finally(() => {
        this.loading = false
      })
    },

    onUpdateNote () {
      this.loading = true
      this.form.ideaPlanId = this.$store.state.idea.ideaPlan.ideaPlans[0].id
      this.form.sectionId = this.sectionId

      this.updateNote(this.form).then(_ => {
        this.closeDialog()
      }).finally(() => {
        this.loading = false
      })
    },

    importNoteData () {
      if (this.color) {
        this.noteColor = this.color
        this.form.color = this.noteColor
      } else {
        this.noteColor = ''
        this.form.color = ''
      }
      this.form.id = this.id
      this.form.title = this.title
      this.form.text = this.text
    },

    closeDialog () {
      this.$emit('close-dialog')
    },

    outsideClick () {
      if (this.isUnsupportedPluginOnIdeaPlan) {
        this.closeDialog()

        return
      }
      if (this.clickedOnSaveButton || this.clickedOutside) return
      if (!this.isNoteEmpty) {
        this.clickedOutside = true
        if (this.isCreateMode) {
          return this.onAddNote()
        }

        return this.onUpdateNote()
      }
      this.closeDialog()
    },

    submitOnEnter (e) {
      if (e) e.preventDefault()

      if (e.altKey) {
        this.saveNote()
      }
    },

    inputTimeOut () {
      if (this.isUnsupportedPluginOnIdeaPlan) {
        this.showUnsupportedPluginNotification(this.form.text)

        return
      }

      const cEditor = this.$refs.quillio.quill
      if (cEditor.getLength() > 5001) {
        this.$notify.closeAll()
        this.$notify({ title: this.$t('error'), message: this.$t('validationMessages.maximumCharacter', { max: 5000 }), type: 'error' })
        this.form.text = this.form.text.substring(0, 5004)
      }
    }
  }
}
</script>

<style scoped lang="scss">
   .idea-plan-note {
     ::v-deep {
       .ql-toolbar {
         background: transparent;
         border-color: transparent !important;
         box-shadow: unset;
         padding: 15px 15px 15px 0;
         border-top: 1px solid rgba(0, 0, 0, 0.09) !important;
         text-align: left;
         margin-top: 10px;
       }
     }
   }
</style>
